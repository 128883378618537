<template>
  <b-card>
    <h4 class="mb-0">
      Edit Penugasan
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="NIP" rules="required">
              <b-form-group label="NIP" label-for="employee_nip" :state="errors.length > 0 ? false : null">
                <v-select id="employee_nip" v-model="dataStudent.employee_nip"
                  :reduce="(employee_nip) => employee_nip.nip" placeholder="Pilih Pegawai"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listPegawai" label="name" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>            
            <validation-provider #default="{ errors }" name="position_id" rules="required">
                <b-form-group label="Jabatan" label-for="position_id" :state="errors.length > 0 ? false : null">
                  <v-select id="position_id" v-model="dataStudent.position_id"
                    :reduce="(position_id) => position_id.real_id" placeholder="Pilih Posisi"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listPosition" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            <b-form-group label="Tanggal Mulai" label-for="start_date">
              <validation-provider #default="{ errors }" name="start_date" rules="required">
                <b-form-input id="start_date" v-model="dataStudent.start_date" :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal Mulai" type="date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Tanggal Selesai" label-for="end_date">
              <validation-provider #default="{ errors }" name="end_date" rules="required">
                <b-form-input id="end_date" v-model="dataStudent.end_date" :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal Selesai" type="date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="SK Penugasan" label-for="decree">
              <validation-provider #default="{ errors }" name="decree" rules="required">
                <b-form-input id="decree" v-model="dataStudent.decree" :state="errors.length > 0 ? false : null"
                  placeholder="SK Penugasan" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Cancel
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <b-container> -->

    <!-- </b-container> -->
  </b-card>
</template>

<script>
import {
  // BContainer,  
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    listPegawai: [],
    listPosition:[],    
    required,
    name: '',
    dataStudent: {
      employee_nip: '',
      position_id: '',
      start_date: '',
      end_date: '',
      decree: '',
    },    
  }),
  computed: {
    
  },
  created() {    
    this.getposition()
    this.getpegawai()
  },
  mounted() {
    this.getStudentDetail()
    this.getposition()
    this.getpegawai()
  },
  methods: {
    async getposition() {
      try {
        const response = await this.$http.get('/employeepositions')
        this.listPosition = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getpegawai() {
      try {
        const response = await this.$http.get('/employees')
        this.listPegawai = response.data.data
        console.log(response.data.data);
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/employeeassignments/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.employee_nip = data.employee_nip
      this.dataStudent.position_id = data.position_id
      this.dataStudent.start_date = data.start_date.substr(0, 10)
      this.dataStudent.end_date = data.end_date.substr(0, 10)
      this.dataStudent.decree = data.decree
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line         
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            dataFormStudent.append('_method', 'PUT')
            await this.$http.post(`/employeeassignments/${studentId}`, dataFormStudent)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Data Penugasan Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'data-penugasan' })
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Data Penugasan Tidak Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            console.log(err)
          }
        }

        // function alertnya() {
        //   alert('form submitted!')
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
