var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"mb-0"},[_vm._v(" Edit Penugasan ")]),_c('validation-observer',{ref:"validUpdate"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"NIP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NIP","label-for":"employee_nip","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"employee_nip","reduce":function (employee_nip) { return employee_nip.nip; },"placeholder":"Pilih Pegawai","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listPegawai,"label":"name","disabled":""},model:{value:(_vm.dataStudent.employee_nip),callback:function ($$v) {_vm.$set(_vm.dataStudent, "employee_nip", $$v)},expression:"dataStudent.employee_nip"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"position_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Jabatan","label-for":"position_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"position_id","reduce":function (position_id) { return position_id.real_id; },"placeholder":"Pilih Posisi","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listPosition,"label":"name"},model:{value:(_vm.dataStudent.position_id),callback:function ($$v) {_vm.$set(_vm.dataStudent, "position_id", $$v)},expression:"dataStudent.position_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Tanggal Mulai","label-for":"start_date"}},[_c('validation-provider',{attrs:{"name":"start_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"start_date","state":errors.length > 0 ? false : null,"placeholder":"Tanggal Mulai","type":"date"},model:{value:(_vm.dataStudent.start_date),callback:function ($$v) {_vm.$set(_vm.dataStudent, "start_date", $$v)},expression:"dataStudent.start_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tanggal Selesai","label-for":"end_date"}},[_c('validation-provider',{attrs:{"name":"end_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"end_date","state":errors.length > 0 ? false : null,"placeholder":"Tanggal Selesai","type":"date"},model:{value:(_vm.dataStudent.end_date),callback:function ($$v) {_vm.$set(_vm.dataStudent, "end_date", $$v)},expression:"dataStudent.end_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"SK Penugasan","label-for":"decree"}},[_c('validation-provider',{attrs:{"name":"decree","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"decree","state":errors.length > 0 ? false : null,"placeholder":"SK Penugasan"},model:{value:(_vm.dataStudent.decree),callback:function ($$v) {_vm.$set(_vm.dataStudent, "decree", $$v)},expression:"dataStudent.decree"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Cancel ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Update ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }